<template>
    <button>
        باشگاه مشتریان
    </button>
</template>
<script>
export default {
    
}
</script>
<style>
    
</style>