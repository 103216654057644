export default {
  watch: {
    '$route.query.page': function (newValue) {
      this.loadItems()
    },
  },
  methods: {
    changePage(page) {
      if (this.disabled) {
        return;
      }
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page: page } })
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (typeof this.url !== 'string') {
          return;
        }
        let page = this.$route.query.page || 1;
        this.show = true
        this.disabled = true
        let search = {};
        if (this.$route.query.search) {
          search = JSON.parse(this.$route.query.search)
        }
        this.$axios.get(this.url, {
          params: {
            page,
            widgets: JSON.stringify(this.widgets),
            ...search
          }
        }).then(response => {
          let objectKey = Object.keys(response.data.data)[0]
          this.items = response.data.data[objectKey]
          if (response.data.data.widgets) {
            this.widgetData = response.data.data.widgets
          }
          this.show = false
          this.disabled = false
          if (response.data.data.store_rules) {
            this.createRules = response.data.data.store_rules;
          }
          if (response.data.data.update_rules) {
            this.editRules = response.data.data.update_rules;
          }

          this.onLoadHandlers.forEach(f => {
            f()
          });
          resolve(response)
        }).catch(error => {
          this.$root.notify(error);
          reject(error);
        })
      });


    },

  }
}
