<template>
  <section
    v-if="tab === 4"
    class="user-information p-3 bg-white   box-shaddow20">
    <h4 class="text-center ">لیست علاقه مندی ها</h4>
    <LineGrow open-width="90%"/>
    <template v-if="status">
      <b-row id="skeletonImage">
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
      </b-row>
      <br />
      <br />
    </template>
    <template v-else>
      <div id="favoriteProduct">
        <Product
          v-for="(product, index) in favorite.data"
          :low-opacity="false"
          :key="index"
          :product="product"
        ></Product>
      </div>
      <h4 class="text-center w-100 mt-5" v-if="favorite.data.length === 0">آیتمی جهت نمایش وجود ندارد</h4>
    </template>

    <section
      id="mainPaginationBlog"
      v-if="favorite && favorite.last_page > 1"
      class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
    >
      <section
        v-if="favorite"
        id="listPagination"
        class="list-pagination weblog-list-pagination text-center my-4 py-2"
      >
        <pagination
          :limit="1"
          :data="favorite"
          @pagination-change-page="changePage"
          :disabled="status"
          class="justify-content-center"
        >
          <span slot="prev-nav">
            <ZPrevSvg />
          </span>
          <span slot="next-nav">
            <ZNextSvg />
          </span>
        </pagination>
      </section>
      <!-- <pagination :data="blogs.posts.links" :router="true" @pagination-change-page="changePage"></pagination> -->
    </section>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import paginationMixin from "@/libraries/mixins/paginationMixin";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import {BRow, BCol, BSkeletonImg} from 'bootstrap-vue'

export default {
  mixins: [paginationMixin],
  props: { tab: { Number } },
  components: {
    LineGrow,
    ZNextSvg,
    ZPrevSvg,
    Product,
    BRow, BCol, BSkeletonImg
  },
  data() {
    return {
      loading: false,allow:false
    };
  },
  created() {
    if (this.favorite == null) {
      this.$store.dispatch(
        "front/getFavoriteFromServer",
        this.$route.query.page != undefined ? this.$route.query.page : "1"
      );
    }
  },
  watch: {
    favorite(newVal) {
      if (newVal != null) {
        this.loading = true;
      }
    },

    "$route.query.page": {
      handler: function (val) {
        let page = val;
        if (this.allow)
          this.$store.dispatch("front/getFavoriteFromServer", page);
      },
      immediate: true,
    },
  },
  computed: {
    status() {
      return this.$store.getters["front/getFavoriteStatus"];
    },
    favorite() {
      if (this.$store.getters["front/getFavorite"] == null) {
        return this.$store.getters["front/getFavorite"];
      } else {
        return this.$store.getters["front/getFavorite"].favorite;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.allow = true;
    }, 5000);
  },
};
</script>
<style scoped>
#skeletonImage {
  display: flex;
  justify-content: space-around;
}
#favoriteProduct {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
}

@media screen and (max-width: 992px){
  #favoriteProduct {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
