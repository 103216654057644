<template>
  <div class="modal fade add-address-modal mobile-friendly" id="AddAddress1" tabindex="-1"
    aria-labelledby="AddAddressLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content  ">
        <div class="modal-header text-right">
          <button type="button" class="close ml-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="modal-title weight-bold text-color-444 mx-auto" id="AddAddressLAbel">
            {{ mode === "create" ? "افزودن آدرس جدید" : "ویرایش آدرس" }}
          </h6>
        </div>
        <div class="modal-body text-center px-4">
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form id="address-form" @submit.prevent="(e) => handleSubmit(() => submitAddress(e))">
              <b-row>
                <b-col md="6" v-if="provinces != null" class="text-right">
                  <b-form-group label="استان" label-align="right">
                    <multiselect v-model="address.province" :allow-empty="false" placeholder="استان"
                      open-direction="bottom" :options="provinces" label="name" track-by="id" :searchable="true"
                      :multiple="false" :close-on-select="true" :show-labels="false" :disabled="disabled">
                      <template slot="noOptions">
                        موردی یافت نشد
                      </template>
                      <template slot="noResult">
                        موردی یافت نشد
                      </template>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider v-if="cities != null" name="city" mode="passive"
                    class="mb-3 d-flex flex-column text-right" rules="" v-slot="v">
                    <label for="">شهر :</label>
                    <div class="variety-title ">
                      <multiselect v-model="address.city" :allow-empty="false" placeholder="شهر" open-direction="bottom"
                        :options="cities" label="name" track-by="id" :searchable="true" :multiple="false"
                        :close-on-select="true" :show-labels="false" :disabled="disabled">
                        <template slot="noOptions">
                          موردی یافت نشد
                        </template>
                        <template slot="noResult">
                          موردی یافت نشد
                        </template>
                      </multiselect>
                    </div>
                    <!-- <input name="categories" @change="v.validate" type="hidden"
                        :value="selected_category != null ? selected_category : ''"> -->
                    <div v-if="address.city != null">
                      <input name="city" @change="v.validate" type="hidden" :value="address.city.id" />
                    </div>
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider name="first_name" mode="passive" rules="" v-slot="v">
                    <label for="">نام :</label>
                    <input v-model="address.first_name" :disabled="disabled" name="first_name" @change="v.validate"
                      type="text" placeholder="نام" class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' " />
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider name="last_name" mode="passive" rules="" v-slot="v">
                    <label for="">نام خانوادگی :</label>
                    <input v-model="address.last_name" :disabled="disabled" name="last_name" @change="v.validate"
                      type="text" placeholder="نام خانوادگی" class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' " />
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider name="postal_code" mode="passive" v-slot="v">
                    <label for="">کد پستی :</label>
                    <input v-model="address.postal_code" :disabled="disabled" name="postal_code" @change="v.validate"
                      type="text" placeholder="کد پستی" class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' " />
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider name="mobile" mode="passive" rules="" v-slot="v">
                    <label for="">موبایل :</label>
                    <input v-model="address.mobile" :disabled="disabled" name="mobile" @change="v.validate" type="text"
                      placeholder="موبایل" class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' " />
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="12" class="text-right">
                  <ValidationProvider name="address" mode="passive" rules="" v-slot="v" class="form-group">
                    <label for="">آدرس :</label>
                    <textarea v-model="address.address" :disabled="disabled" name="address" @change="v.validate"
                      type="text" class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "></textarea>
                    <span class="text-right text-danger mt-2 mx-2">{{
                    v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="text-center mt-3">
                <button class="btn" :disabled="disabled" :class="mode == 'create' ? 'btn-success' : 'btn-primary'"
                  type="submit">
                  {{ mode == "create" ? "ثبت" : "ویرایش" }} آدرس
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import closeModalMixin from '@/libraries/mixins/closeModalMixin'
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow, BCol, BFormGroup
  },
  mixins: [closeModalMixin],
  props: {
    mode: {
      type: String,
      default: "create",
    },
    value: Object,
    adminProvinces: null,
  },
  data() {
    return {
      baseUrl: "/addresses",
      url: "",
      cities: null,
      disabled: false,
      auth: "front",
      address: this.value,
      modals: ['AddAddress1']
    };
  },
  created() {
    if (this.$root.isPart("admin")) {
      this.auth = "admin";
    }
  },
  mounted() {
    if (this.auth === "admin") {
      this.baseUrl = "admin/customers" + this.baseUrl;
    } else {
      if (this.$store.getters[this.auth + "/getProvincesData"] == null) {
        this.$store.dispatch(this.auth + "/getProvincesDataFromServer");
      }
      this.baseUrl = "customer" + this.baseUrl;
    }
  },
  computed: {
    provinces() {
      if (this.auth === "admin") {
        return this.adminProvinces;
      } else {
        return this.$store.getters["front/getProvincesData"];
      }
    },
  },
  watch: {
    address(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.address = newVal;
    },
    "address.province": function (newVal) {
      if (newVal) {
        this.address.city = null
        let cities = this.provinces.filter((item) => item.id == newVal.id)[0]
          .cities;
        this.cities = cities;
      }
    },
    mode(newVal) {
      if (newVal) {
        this.resetVeeValidate();
      }
      if (newVal == 'create') {
        this.cities = null
      }
    },
  },
  methods: {
    resetVeeValidate() {
      this.$refs.observer.reset();
    },
    setCities(items) {
      this.cities = items.cities;
    },
    async submitAddress(e) {
      this.disabled = true;
      this.mode == "edit"
        ? (this.url = this.baseUrl + "/" + this.address.id)
        : (this.url = this.baseUrl);

      try {
        let formData = new FormData(e.target);
        // formData.append("city", this.address.city ? this.address.city.id : "");
        formData.append("_method", this.mode == "edit" ? "put" : "post");
        formData.append(
          "customer_id",
          this.auth === "admin" ? this.address.customer_id : ""
        );
        const response = await this.$axios.post(this.url, formData);
        // const response = await this.$axios.post(this.url, {
        //   city: this.address.city.id,
        //   first_name: this.address.first_name,
        //   last_name: this.address.last_name,
        //   postal_code: this.address.postal_code,
        //   mobile: this.address.mobile,
        //   address: this.address.address,
        //   _method: this.mode == "edit" ? "put" : "post",
        //   customer_id: this.auth === "admin" ? this.address.customer_id : "",
        // });

        this.$emit("new-address", response.data.data.address);
        this.$root.notify(response.data.message, "success");
        window.$("#AddAddress1").modal("hide");
        this.cities = null;
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    resetForm() {
      this.$("form#address-form input").val("");
    },
  },
};
</script>
<style>
#AddAddress1 .modal-header .close {
  margin-right: 0;
}

.add-address-modal .modal-body,
.add-address-modal .modal-dialog-scrollable .modal-content {
  overflow: unset;
}

.add-address-modal .multiselect__tags,
.add-address-modal .form-control {
  border: 1px solid var(--color-ea);
  border-radius: 10px;
}

.add-address-modal .multiselect__placeholder {
  color: #777;
}

.add-address-modal .multiselect,
.add-address-modal .multiselect__input,
.add-address-modal .multiselect__single,
.add-address-modal .form-control {
  font-size: 16px !important;
}

.add-address-modal .multiselect__option--highlight::after,
.add-address-modal .multiselect__option--highlight {
  background: var(--color-theme);
}

.add-address-modal .multiselect__option--selected.multiselect__option--highlight::after,
.add-address-modal .multiselect__option--selected.multiselect__option--highlight {
  background: var(--color-theme);
}

.add-address-modal .form-control {
  height: 42px;
  box-shadow: unset;
}
</style>
