<template>
  <svg
      class="page-arrow mb-2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 226 226"
      style="fill: #000000"
  >
    <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
    >
      <path d="M0,226v-226h226v226z" fill="none"></path>
      <g fill="#b2b2b2">
        <path
            d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ZPrevSvg"
}
</script>

<style scoped>

</style>
