<template>
  <div class="question-tab-content-text position-relative">
    <div class="d-flex flex-wrap">
      <p class="fontsize-small weight-bold text-dark mb-0">
        {{ comment.name }}
      </p>
      <p class="date fontsize-mini text-secondary mr-2 mt-1" style="word-spacing: 4px">
        {{ moment(comment.created_at).format('HH:mm YYYY/MM/DD') }}
      </p>
    </div>

    <p class="user-question-text fontsize-small text-dark text-justify">
      {{ comment.body }}
    </p>
    <template v-if="answerable" >
      <a class="text-color-themeBlue fontsize-mini weight-bold" @click.prevent="$emit('answer', comment.id)">
        پاسخ دهید
        <ZSvgCommentAnswer />
      </a>
      <div class="mt-2 window" :class="{open: answeringComment === comment.id}">
        <transition name="window-fade">
          <CommentForm @commentAdded="onCommentAdded" :blog-id="blogId" v-if="answeringComment === comment.id" :parent-id="comment.id"  />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import CommentForm from "@/parts/Front/components/weblog/CommentForm";
import ZSvgCommentAnswer from "@/parts/Front/components/weblog/ZSvgCommentAnswer";
export default {
  name: "Comment",
  components: {ZSvgCommentAnswer, CommentForm},
  props: {
    answerable: Boolean,
    comment: Object,
    answeringComment: {
      default: null,
      type: [Number, String]
    },
    blogId: [Number,String]
  },
  methods: {
    onCommentAdded(comment) {
      this.comment.children.unshift(comment)
      this.$emit('commentAdded', null)
    }
  }
}
</script>

<style scoped>
.window {
  display: block;
  min-height: 0;
  width: 100%;
  transition: 200ms all;
  position: relative;
}

.window.open {
  min-height: 365px;
}

.window > * {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.window-fade-leave-active,
.window-fade-enter-active {
  transition: 300ms;
}
.window-fade-enter {
  opacity: 0;
  transform: translate(0, 2%);
}
.window-fade-leave-to {
  opacity: 0;
  transform: translate(0, -2%);
}
@media screen and (max-width: 600px){
  .window.open {
    min-height: 370px;
  }
  .date {
    position: absolute;
    left: 0;
    top: 0;
  }
}

</style>
