<template>
  <div class="in-user-panel">
    <PagesPath :pathTitle1="'حساب کاربری'" :pathTo1="'/user-panel'"></PagesPath>
    <div id="mainPanel" class="panel-main-grid py-4 container">
      <section class="panel-side p-3 bg-white   box-shaddow20 fontsize-small">
        <div class="d-flex justify-content-center mt-4">
          <b-skeleton v-if="status || !user" type="avatar"></b-skeleton>
          <div v-else class="user-panel-img ml-3" id="userProfileImage">
            <img
                v-if="user && user.image != null"
                id="profileImage"
                :src="user.image.url"
            />
            <img v-else/>
            <div @click="selectFile()" id="eidtProfileImage">
              <i class="fa fa-edit"></i>
            </div>
          </div>

          <input
              id="chooseImage"
              type="file"
              accept="image/*"
              @change="changeProfileImage"
          />
          <div class="d-flex flex-column mt-3">
            <b-skeleton
                class="userHeaderInfoSkeleton"
                v-if="status || !user"
            ></b-skeleton>
            <template v-else>
              <span
                  v-if="user && user.full_name != null"
                  class="fontsize16 weight-bold text-color-444"
              >{{ user.full_name }}</span
              >
              <span v-else class="fontsize16 weight-bold text-color-444"
              >حساب کاربری</span
              >
            </template>
            <b-skeleton
                class="userHeaderInfoSkeleton"
                v-if="status || !user"
            ></b-skeleton>
            <span v-else class="fontsize15 text-color-666">{{
                user.mobile
              }}</span>
          </div>
        </div>
        <b-skeleton v-if="status"></b-skeleton>

        <div v-else id="walletOption">
          <div>
            <span id="amountWalletText"> موجودی کیف پول: </span>
            <span id="amountwallet" class="mr-1">{{ user.wallet.balance | price }} تومان</span>
          </div>
          <b-button v-b-tooltip.left.hover title="شارژ کیف پول"
              data-toggle="modal"
              data-target="#depositModal"
              id="despositWallet">
            +
          </b-button>
        </div>
        <ul id="userPanelOption" class="py-5">
          <li class="mb-2">
            <button
                @click="changeTab(1)" class="w-100 h-100   text-right py-2 px-3"
                :class="{ 'panel-side-item-active': tab === 1, 'panel-side-item-deactive': tab !== 1 }">
              <svg class="ml-2" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.5789" cy="7.27803" r="4.77803" stroke="#444" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
                      stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>حساب کاربری</span>
            </button>
          </li>
          <li class="mb-2">
            <button
                @click="changeTab(2)"
                class="w-100 h-100 text-right   py-2 px-3"
                :class="{ 'panel-side-item-active': tab === 2, 'panel-side-item-deactive': tab !== 2 }">
              <svg class="ml-2" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.23913 10.3913C4.25354 6.15071 7.70289 2.72471 11.9435 2.73912C16.1841 2.75353 19.6101 6.20288 19.5957 10.4435V10.5304C19.5435 13.2869 18.0043 15.8348 16.1174 17.8261C15.0382 18.9467 13.8331 19.9388 12.5261 20.7826C12.1766 21.0849 11.6582 21.0849 11.3087 20.7826C9.3602 19.5143 7.65007 17.9131 6.25652 16.0522C5.01448 14.4294 4.3093 12.4597 4.23913 10.4174L4.23913 10.3913Z"
                      stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <circle cx="11.9174" cy="10.5391" r="2.46087" stroke="#200E32" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
              </svg>

              <span> لیست آدرس ها</span>
            </button>
          </li>
          <li class="mb-2">
            <button
                @click="changeTab(3)"
                class="w-100 h-100 text-right   py-2 px-3"
                :class="{ 'panel-side-item-active': tab === 3, 'panel-side-item-deactive': tab !== 3 }">
              <svg class="ml-2" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M15.7162 16.2234H8.4962"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.7162 12.0369H8.4962"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.2513 7.86008H8.4963"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.9086 2.74979C15.9086 2.74979 8.2316 2.75379 8.2196 2.75379C5.4596 2.77079 3.7506 4.58679 3.7506 7.35679V16.5528C3.7506 19.3368 5.4726 21.1598 8.2566 21.1598C8.2566 21.1598 15.9326 21.1568 15.9456 21.1568C18.7056 21.1398 20.4156 19.3228 20.4156 16.5528V7.35679C20.4156 4.57279 18.6926 2.74979 15.9086 2.74979Z"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
              <span> پیگیری سفارش</span>
            </button>
          </li>
          <li class="mb-2">
            <button
                class="w-100 h-100 text-right   py-2 px-3"
                @click="changeTab(4)"
                :class="{ 'panel-side-item-active': tab === 4, 'panel-side-item-deactive': tab !== 4 }">
              <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.761 20.8538C9.5904 19.5179 7.57111 17.9456 5.73929 16.1652C4.45144 14.8829 3.47101 13.3198 2.8731 11.5954C1.79714 8.25031 3.05393 4.42083 6.57112 3.28752C8.41961 2.69243 10.4384 3.03255 11.9961 4.20148C13.5543 3.03398 15.5725 2.69398 17.4211 3.28752C20.9383 4.42083 22.2041 8.25031 21.1281 11.5954C20.5302 13.3198 19.5498 14.8829 18.2619 16.1652C16.4301 17.9456 14.4108 19.5179 12.2402 20.8538L12.0051 21L11.761 20.8538Z"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.7393 7.05301C16.8046 7.39331 17.5615 8.34971 17.6561 9.47499"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
              <span>لیست علاقه مندی ها</span>
            </button>
          </li>

          <li class="mb-2">
            <button
                class="w-100 h-100 text-right   py-2 px-3"
                @click="changeTab(5)"
                :class="{ 'panel-side-item-active': tab === 5, 'panel-side-item-deactive': tab !== 5 }">
              <svg id="walletSvg" version="1.1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="969.486px" height="969.486px"
                   viewBox="0 0 969.486 969.486" style="enable-background: new 0 0 969.486 969.486" xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                        d="M806.582,235.309L766.137,87.125l-137.434,37.51L571.451,9.072L114.798,235.309H0v725.105h907.137V764.973h62.35v-337.53
			h-62.352V235.309H806.582z M718.441,170.63l17.654,64.68h-52.561h-75.887h-126.19l111.159-30.339l66.848-18.245L718.441,170.63z
			 M839.135,892.414H68V522.062v-129.13v-10.233v-69.787v-9.602h35.181h27.538h101.592h409.025h75.889h37.43h35.242h35.244h13.994
			v51.272v72.86h-15.357h-35.244h-87.85H547.508h-55.217v27.356v75.888v8.758v35.244v35.244v155.039h346.846v127.441H839.135z
			 M901.486,696.973h-28.352h-34H560.291V591.375v-35.244v-35.244v-23.889v-1.555h3.139h90.086h129.129h56.492h34h4.445h23.904
			V696.973z M540.707,100.191l21.15,42.688l-238.955,65.218L540.707,100.191z"
                    />
                    <polygon
                        points="614.146,564.57 614.146,576.676 614.146,631.152 680.73,631.152 680.73,564.57 658.498,564.57 		"
                    />
                  </g>
                </g>
              </svg>
              <span>کیف پول</span>
            </button>
          </li>
          <li class="mb-2">
            <button
                class="w-100 h-100 text-right   py-2 px-3"
                @click="changeTab(6)"
                :class="{ 'panel-side-item-active': tab === 6, 'panel-side-item-deactive': tab !== 6 }">
              <svg id="moneySvg" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                 viewBox="0 0 475 475" style="enable-background:new 0 0 475 475;" xml:space="preserve">
                <g transform="translate(0 -540.36)">
                  <g>
                    <g>
                      <path d="M473,703.31l-66.7-70.6c-1.4-1.5-3.4-2.4-5.5-2.4H77.3c-2,0-3.9,0.8-5.3,2.2l-69.8,70.6c-1.4,1.4-2.2,3.3-2.2,5.3v209.5
                        c0,4.1,3.4,7.5,7.5,7.5h460c4.1,0,7.5-3.4,7.5-7.5v-209.5C475,706.51,474.3,704.71,473,703.31z M160.6,910.41H15v-37.6h145.6
                        V910.41z M160.6,857.91H15v-37.6h145.6V857.91z M160.6,805.31H15v-36.8h145.6V805.31z M160.6,753.51H15v-37.6h145.6V753.51z
                         M25.5,700.91l54.9-55.6h118.7l-36.7,55.6H25.5z M300.2,910.41H175.6v-202.1l41.5-63h41.6l41.5,63V910.41z M276.6,645.31
                        L276.6,645.31h120.9l52.5,55.6H313.3L276.6,645.31z M460,910.41H315.2v-37.6H460V910.41z M460,857.91H315.2v-37.6H460V857.91z
                         M460,805.31H315.2v-36.8H460V805.31z M460,753.51H315.2v-37.6H460V753.51z"/>
                      <path d="M245,761.21v-19c0-4.1-3.4-7.5-7.5-7.5c-4.1,0-7.5,3.4-7.5,7.5v18.4c-1.4,0.3-2.8,0.7-4.2,1.3c-0.2,0.1-0.4,0.2-0.7,0.3
                        c-13,6.6-20,20.6-17.1,34.1c3,14.1,14.9,24,29.1,24h0.8c7.1,0,12.7,5.1,14.4,12.9v0.1c1.5,6.8-2.2,14-8.6,16.5
                        c-0.1,0-0.1,0.1-0.2,0.1c-5.5,2.3-13.3,0.9-17-5.5c-2.1-3.6-6.7-4.8-10.2-2.8c-3.6,2.1-4.8,6.7-2.8,10.2
                        c3.7,6.5,9.7,10.9,16.5,12.9v18.7c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5v-18.3c1.4-0.4,2.8-0.8,4.2-1.4
                        c13.3-5.2,20.9-19.7,17.7-33.7h0c-3.2-14.8-14.8-24.7-29-24.7h-0.8c-7,0-12.9-5-14.4-12.1c-1.7-7.9,3.2-14.5,8.9-17.5
                        c6.4-2.3,13.4,0,17,5.8c2.2,3.5,6.8,4.6,10.3,2.4c3.5-2.2,4.6-6.8,2.4-10.3C257.4,767.51,251.6,763.21,245,761.21z"/>
                    </g>
                  </g>
                </g>
              </svg>
              <span>برداشت از کیف پول</span>
            </button>
          </li>
          <li class="mb-2">
            <button
                @click.prevent="signOut()"
                :class="{ disabled: disabledlLogout }"
                class="w-100 h-100 text-right   py-2 px-3 panel-side-item-deactive">
              <svg class="ml-2" width="20" height="20" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M15.016 7.38951V6.45651C15.016 4.42151 13.366 2.77151 11.331 2.77151H6.45597C4.42197 2.77151 2.77197 4.42151 2.77197 6.45651V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M21.8095 12.0214H9.76849"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.8812 9.10632L21.8092 12.0213L18.8812 14.9373"
                    stroke="#200E32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>

              <span class="text-color-themeRed"> خروج از حساب کاربری</span>
            </button>
          </li>
        </ul>
      </section>

      <transition name="tab-fade">
        <user-detail v-if="tab === 1" :tab="tab"></user-detail>
        <user-address v-else-if="tab === 2" :tab="tab"></user-address>
        <orders v-else-if="tab == 3" :tab="tab"></orders>
        <Favorite v-else-if="tab == 4" :tab="tab"></Favorite>
        <Wallet v-else-if="tab == 5" :tab="tab"/>
        <Withdraw v-else-if="tab == 6" :tab="tab"/>
      </transition>
    </div>
    <CropImage id="crop-profile-2" :image="img"/>
    <Deposit/>
  </div>
</template>

<script>
import costumersClub from "@/parts/Front/components/costumersClub";
import PagesPath from "@/parts/Front/components/PagesPath";
import CropImage from "@/parts/Front/components/CropImage";
import UserAddress from "../components/panel/userAddress.vue";
import UserDetail from "../components/panel/userDetail.vue";
import Orders from "../components/panel/orders.vue";
import Favorite from "../components/panel/Favorite.vue";
import Wallet from "@/parts/Front/components/panel/Wallet.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Deposit from "@/parts/Front/components/panel/Wallet/DepositWallet.vue";
import {BSkeleton, BButton, VBTooltip} from 'bootstrap-vue'
import Withdraw from "@/parts/Front/components/panel/Withdraw";

export default {
  name: "UserPanel",
  components: {
    Withdraw,
    PagesPath,
    costumersClub,
    UserAddress,
    UserDetail,
    Orders,
    Favorite,
    CropImage,
    Wallet,
    Deposit,
    BSkeleton, BButton
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  created() {

  },
  mounted() {
    this.$store.commit("front/resetPanelData");
    if (this.$route.query.section != undefined) {
      let section = this.$route.query.section;
      if (section == "orders") {
        this.tab = 3;
      } else if (section == "favorites") {
        this.tab = 4;
      } else if (section == "wallet") {
        this.tab = 5;
      } else if (section == 'withdraw') {
        this.tab = 6;
      } else {
        this.tab = 1;
      }
    }
  },
  watch: {
    tab() {
      if (window.innerWidth < 768) {
        setTimeout(() => {
          document.querySelector('.panel-main-grid > *:nth-child(2)').scrollIntoView({behavior: 'smooth'})
        }, 400)
      }

    }
  },
  data() {
    return {
      tab: 1,
      disabledlLogout: false,
      img: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch("front/getUserDetailFromServer");
  },
  methods: {
    changeTab(number) {
      if (number == 3) {
        this.tab = 3;
        this.$router.push(this.$root.makePath("/user-panel", {...this.$route.query, section: "orders"}));
      } else if (number == 4) {
        this.tab = 4;
        this.$router.push(this.$root.makePath("/user-panel", {...this.$route.query, section: "favorites"}));
      } else if (number == 5) {
        this.tab = 5;
        this.$router.push(this.$root.makePath("/user-panel", {...this.$route.query, section: "wallet"}));
      } else if (number == 6) {
        this.tab = 6;
        this.$router.push(this.$root.makePath("/user-panel", {...this.$route.query, section: "withdraw"}));
      } else {
        this.tab = number;
        if (this.$route.query.section != undefined) {
          let query = Object.assign({}, this.$route.query);
          delete query.section;
          if (query.page != undefined) delete query.page;
          if (query.page != undefined) delete query.status;
          this.$router.replace({query});
        }
      }
    },
    selectFile() {
      document.getElementById("chooseImage").click();
    },
    changeProfileImage(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      else {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.img = e.target.result;
          window.$("#crop-profile-2").modal("show");
          event.target.value = ''
        };

        reader.readAsDataURL(files[0]);
      }
    },
    signOut() {
      window
          .swal({
            title: "آیا میخواهید خارج شوید؟",

            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله خارج شو"],
          })
          .then((willDelete) => {
            if (willDelete) {
              this.disabledlLogout = true;
              this.$axios.post("/customer/logout").then((res) => {
                this.disabledlLogout = false;
                // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
                this.$root.deleteCookie("Authorization");
                delete this.$axios.defaults.headers.common["Authorization"];
                this.$store.commit("front/setLoginStatus", false);
                this.$router.push('/');
                this.$root.notify('با موفقیت خارج شدید', 'success')
              });
            }
          });
    },
  },
  computed: {
    user() {
      return this.$store.getters["front/getUserDetail"];
    },
    status() {
      return this.$store.getters["front/getUserDetailStatus"];
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* تنظیمات اسکلتون اطلاعات کاربری ساید بار راست */
.userHeaderInfoSkeleton {
  width: 40px;
  margin-right: 10px;
}

.b-skeleton-avatar {
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
}

#mainPanel {
  width: 100%;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#userProfileImage {
  position: relative;
}

#userProfileImage:hover #eidtProfileImage {
  display: block;
}

#eidtProfileImage {
  display: none;
  transition: all 0.5s;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #000000ab;
}

#eidtProfileImage i {
  position: relative;
  right: 25px;
  top: 25px;
  color: #ff8500;
}

#profileImage {
  width: 80px;
  height: 80px;
  object-fit: fill;
}

#chooseImage {
  opacity: 0;
  position: fixed;
  z-index: 0;
  pointer-events: none;
}

#walletSvg {
  width: 22px;
  height: 20px;
  margin-left: 5px;
  transform: translateY(-4px);
}

#moneySvg {
  width: 26px;
  height: 26px;
  margin-left: 5px;
  transform: translateY(-4px);
}

#despositWallet {
  background: #121314;
  color: white;
  padding: 0 0px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 22px;
  border: none;
}

#amountWalletText {
  font-weight: bolder;
  font-size: smaller;
}

#amountWalletText {
  font-weight: small;
}

#walletOption {
  margin-top: 12px;
  display: flex;
  justify-content: space-evenly;
}

#userPanelOption {
  padding-top: 2rem !important;
}

.in-user-panel >>> .page-item.pagination-page-nav .page-link {
  background: #f5eae7;
  color: #636363;
}

.in-user-panel >>> .page-item.pagination-page-nav.active .page-link {
  background-color: var(--color-theme);
  color: #fff;
}


.tab-fade-enter-active, .tab-fade-leave-active {
  transition: opacity .25s;
}

.tab-fade-enter /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0.1;
}

.tab-fade-leave {
  display: none;
}

.tab-fade-leave-to {
  display: none;
}


.panel-side ul {
  list-style-type: none;
}

.panel-side ul li > button {
  background: none;
}


.panel-side ul li > button.panel-side-item-active {
  background-color: var(--color-theme2);
  color: var(--color-theme);
}

.panel-side ul li > button.panel-side-item-active svg > circle,
.panel-side ul li > button.panel-side-item-active svg > path {
  stroke: var(--color-theme);
}

.panel-side-item-deactive {
  transition: 200ms all;
}

#userPanelOption li:hover .panel-side-item-deactive {
  transform: translateX(-5px);
}

.panel-side .panel-side-item-active svg > g {
  fill: var(--color-theme);
}

.panel-main-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 15px;
}

.user-panel-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: gray;
  border: 2px solid lightblue;
}

@media screen and (max-width: 991px) {
  .panel-main-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .panel-main-grid {
    grid-template-columns: 1fr;
  }
}
</style>
