<template>
  <div class="line bg-color-c8 mb-4" :style="{width: open ? openWidth : closeWidth, transition: transitionTime + ' all'}" style="margin: auto"></div>
</template>

<script>
export default {
  name: "LineGrow",
  props: {
    openWidth: {
      type: [String, Number],
      default: '80%'
    },
    closeWidth: {
      type: [String, Number],
      default: '0%'
    },
    transitionTime: {
      type: String,
      default: '400ms'
    },
    delay: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      open: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.open = true
    },this.delay)
  }
}
</script>
