<template>
<span class="badge p-2 fontsize-mini" :style="`background: ${getStatusColor(status)}; color:${$root.dynamicColor(status)}`">
  {{ getOrderStatus(status) }}
</span>
</template>

<script>
import {
  getStatusColor,
  getOrderStatus
} from "@/libraries/functions";
export default {
  name: "OrderStatus",
  props: {
    status: String
  },
  methods: {
    getStatusColor,
    getOrderStatus
  }
}
</script>

<style scoped>
.badge {
  min-width: 80%;
}
</style>
