<template>
  <form @submit.prevent="submit" :id="'form-' + parentId" class="mb-2" :class="{'mb-0': !parentId, 'mb-xl-4': parentId}">
    <div class="d-flex flex-lg-row flex-column flex-wrap justify-content-right fontsize-small">
      <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
      <input required v-else id="fullNameComment" v-model="fullName" name="name"
             class="bg-color-ea py-1 px-2 mb-2 fontsize-small" placeholder="نام کامل*" type="text"/>
      <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
      <input required v-else id="emailComment" v-model="email" class="bg-color-ea py-1 px-2 mb-2 mr-lg-2"
             placeholder=" آدرس ایمیل*" type="email" name="email"/>
    </div>
    <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
    <textarea
        v-model="body" id="textComment" v-else class="bg-color-ea py-3 px-2 w-100 mb-2 fontsize-small"
        name="body" cols="30" rows="9" placeholder="دیدگاه شما*"
    ></textarea>
    <p class="text-danger mb-1 pr-4 comment-error">{{ commentError }}</p>
    <div class="send-comment-btn-container text-left">
      <b-skeleton v-if="blogStatus" type="button"></b-skeleton>
      <div v-else class="py-1 w-md-25 w-60 position-relative">
        <Loading class="comment-loading" v-if="disabled" />
        <input :disabled="disabled"
            class="send-comment-btn bg-color-theme text-white fontsize-small weight-bold"
            type="submit" value="ارسال پیام"/>
      </div>
    </div>
  </form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Loading from '@/parts/Front/components/shared/Loading'
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "CommentForm",
  components: {Loading, BSkeleton},
  props: {
    parentId: {
      type: [Number, String],
      default: null
    },
    blogId: [Number,String]
  },
  data() {
    return {
      fullName: '',
      email: '',
      body: '',
      disabled: false,
      commentError: ''
    }
  },
  computed: {
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
  },
  created() {
    if (window.backup_comment_form_component && window.backup_comment_form_component.id === this.parentId) {
      window.backup_comment_form_component.data.forEach((val, index) => this.$data[index] = val)
    }
  },
  methods: {
    async submit() {
      if (this.body === '') {
        this.commentError = 'وارد کردن متن الزامی است';
        return;
      }
      this.disabled = true;
      try {
        console.log({
          name: this.fullName,
          email: this.email,
          body: this.body,
          parent_id: this.parentId ? this.parentId : undefined
        })
        const response = await this.$axios.post(`front/posts/${this.blogId}/comments`, {
          name: this.fullName,
          email: this.email,
          body: this.body,
          parent_id: this.parentId ? this.parentId : undefined
        });
        this.$root.notify(response.data.message, 'success');
        this.resetData();
        this.$emit('commentAdded', response.data.data.comment);
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    resetData() {
      let initialData = this.$options.data.apply(this);
      for (let [index] of Object.entries(this.$data)) {
        this.$data[index] = initialData[index]
      }
    }
  },
  beforeDestroy() {
    // اگه دستش خورد بسته شد دوباره باز شد بیاد
    window.backup_comment_form_component = {
      id: this.parentId,
      data: cloneDeep(this.$data)
    };
  },
}
</script>

<style scoped>
form input[type=text], form input[type=email], textarea {
  transition: 200ms all;
}

form input[type=text]:focus, form input[type=email]:focus, textarea:focus {
  box-shadow: 0 0 1px 1px #745d45;
}

form input[type=submit]:disabled, form button:disabled {
  opacity: 0.6;
}

.send-comment-btn {
  width: 100%;
  height: 40px;
}

.send-comment-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.send-comment-btn-container .comment-loading {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
}
.w-60 {
  width: 60%!important;
}
.comment-error {
  position: absolute;
}
@media screen and (max-width: 768px){
  .comment-error {
    position: relative;
  }
}
@media only screen and (min-width: 768px) {
  .comment-error {
    position: absolute;
  }
  .w-md-25 {
    width: 25% !important;
  }
}

</style>
