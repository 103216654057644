<template>
  <div>درحال هدایت به صفحه مورد نظر ...</div>
</template>
<script>
export default {
  mounted() {
    if (this.$route.query.type == "wallet") {
      this.$router.push("/user-panel?section=wallet")
    } else {
      this.$router.push("/order/"+this.$route.query.payable_id)
    }
  },
};
</script>
<style scoped>
</style>