<template>

</template>
<script>


export default {
  components: {
 
  },
  
  props: {
    mode: {
      type: String,
      default: "create",
    },
    value: Object,
    adminProvinces: null,
  },
  data() {
    return {
    
    };
  },
  created() {
    if (this.$root.isPart("admin")) {
      this.auth = "admin";
    }
  },
  mounted() {
    if (this.auth === "admin") {
      this.baseUrl = "admin/customers" + this.baseUrl;
    } else {
      if (this.$store.getters[this.auth + "/getProvincesData"] == null) {
        this.$store.dispatch(this.auth + "/getProvincesDataFromServer");
      }
      this.baseUrl = "customer" + this.baseUrl;
    }
  },
  computed: {
    packingOptionsBasedOnOrder() {
      // اگه سمت فرانت سرت میشه
      //     return this.packingOptions.sort((a, b) => {
      //     return a.order - b.order ;
      //   });

      // اگه سمت بک سرت میشه
      return this.packingOptions;
    },
  },
  watch: {
    mode(newVal) {
      if (newVal) {
        this.resetVeeValidate();
      }
      if (newVal == "create") {
        this.cities = null;
      }
    },
  },
  methods: {
      nextPage() {

      this.$emit("next-page");
    },
    resetVeeValidate() {
      this.$refs.observer.reset();
    },
    setCities(items) {
      this.cities = items.cities;
    },

    resetForm() {
      this.$("form#address-form input").val("");
    },
  },
};
</script>
<style>
.packing-confirm-button-container {
  display: flex;
}
.packing-confirm-button {
  bottom: 0;
  width: 65%;
  font-size: 20px;
  color: white;
  padding: 13px 0;
  margin: 24px auto;
  background-color: black;
}
.add-package-type {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
}
.packageItem {
  display: grid;
  grid-template-columns: 70% auto;
}
.packageItem > div:first-child {
  /* align-self: center;
  justify-self: flex-start; */
  display: flex;
  justify-content: start;
  align-items: center;
}
.packageItem > div:nth-child(2) {
  padding: 5px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.circle-select {
  width: 20px;
  height: 20px;
  background-color: #d9d9d9;
  border-radius: 20px;
}
.modal-content {
  position: relative;
}
.packageItem div:nth-child(2) > span:first-child {
  color: #999;
}
#AddAddress1 .modal-header .close {
  margin-right: 0;
}
.add-address-modal .modal-body,
.add-address-modal .modal-dialog-scrollable .modal-content {
  overflow: unset;
}
.add-address-modal .multiselect__tags,
.add-address-modal .form-control {
  border: 1px solid var(--color-ea);
  border-radius: 10px;
}
.add-address-modal .multiselect__placeholder {
  color: #777;
}
.add-address-modal .multiselect,
.add-address-modal .multiselect__input,
.add-address-modal .multiselect__single,
.add-address-modal .form-control {
  font-size: 16px !important;
}

.add-address-modal .multiselect__option--highlight::after,
.add-address-modal .multiselect__option--highlight {
  background: var(--color-theme);
}

.add-address-modal
  .multiselect__option--selected.multiselect__option--highlight::after,
.add-address-modal
  .multiselect__option--selected.multiselect__option--highlight {
  background: var(--color-theme);
}
.add-address-modal .form-control {
  height: 42px;
  box-shadow: unset;
}
</style>
