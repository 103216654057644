<template>
  <div class="modal fade crop-image-modal mobile-friendly" :id="id">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable " id="cropImageBox">
      <div class="modal-content  " id="headerCropper">
        <div class="modal-header text-right">
          <button
              type="button"
              class="close ml-0"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="modal-title weight-bold text-color-444 mx-auto">
            تغییر عکس پروفایل
          </h6>
        </div>
        <div class="modal-body text-center px-4">
          <div class="cropper-container">
            <vue-cropper
                ref="cropper"
                :aspectRatio="1"
                :src="imgSrc"
                :data="{width: 400,height:  400}"
                width="400"
                height="400"
                :autoCropArea="1"
                :min-crop-box-width="120"
                :min-crop-box-height="120"
                :min-container-height="mediaQueries.mobileSize ? 200 : 300"
                :min-container-width="mediaQueries.mobileSize ? 300 : 400"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button :disabled="disabled" type="button" class="btn btn-light mr-3" data-dismiss="modal">بستن</button>
          <button :disabled="disabled" type="button" @click="submit" class="btn" id="chooseBtn">
            انتخاب
          </button>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import closeModalMixin from '@/libraries/mixins/closeModalMixin'

export default {
  components: {
    VueCropper,
  },
  mixins: [closeModalMixin],
  inject: ['mediaQueries'],
  props: {
    image: {Object},
    id: {
      default: 'crop-profile',
      type: String
    }
  },
  data() {
    return {
      imgSrc: null,
      disabled: false,
      modals: [this.id]
    }
  },
  methods: {
    getCroppedImage() {
      return this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    async submit() {
      try {
        this.disabled = true
        const response = await this.$axios.post('customer/profile/image', {
          image: this.getCroppedImage(),
          _method: 'PUT'
        })
        this.$store.commit('front/setProfileImage', response.data.data.image)
        this.$root.notify(response.data.message, 'success')
        window.$('#' + this.id).modal('hide')
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    }
  },
  watch: {
    image(newImage) {
        this.imgSrc = newImage
        this.$refs.cropper.replace(newImage);
    }
  }
};
</script>
<style scoped>
#chooseBtn {
  background-color: #121314;
  color: white;
}
</style>
<style>
#cropImageBox * {
  direction: ltr !important;
}

.crop-image-modal .cropper-container {
  width: 100%;
  height: 300px;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
}
</style>
