<template>
  <section class="payment-method bg-white box-shaddow20 container mb-5">
    <div class="d-flex text-right p-3 border-bottom">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6389 14.3958H17.5906C16.1042 14.3949 14.8994 13.1909 14.8985 11.7045C14.8985 10.218 16.1042 9.01413 17.5906 9.01321H21.6389"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0486 11.6429H17.7369"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.03561 7.53817H12.4346"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h6 class="fontsize-large text-color-444 mt-1 mr-1 weight-bold">
        انتخاب شیوه پرداخت
      </h6>
    </div>
    <div class="row p-3">
      <section class="internet-payment col-md-6 col-12 p-3 bg-color-fa border">
        <div
          @click="(e) => selectPayMethod(e, true)"
          :style="requestingWallet ? 'opacity: 0.6' : ''"
          class="cursor-pointer d-flex align-items-center mb-2"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                ref="radioWallet"
                type="radio"
                :checked="payWallet"
                name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>

          <svg
            class="mr-3"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7689 9.87241H21.2533C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241ZM17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1555 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533ZM6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.63649C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97331 8.04087 6.31415 8.37705 6.73778 8.3818Z"
              fill="#666"
            />
          </svg>
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            <span>پرداخت از کیف پول</span>
            <span> (موجودی فعلی: {{ user.wallet.balance | price }} تومان)</span>
          </h6>
        </div>
        <div
          @click="(e) => selectPayMethod(e, false)"
          class="cursor-pointer d-flex align-items-center mb-1"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                ref="radioGateway"
                type="radio"
                :checked="!payWallet"
                name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>
          <img
            :src="require('@/assets/images/money.png')"
            class="wallet-img mr-3"
            :alt="'wallet'"
          />
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            پرداخت اینترنتی
          </h6>
        </div>
        <p class="text-color-666 fontsize-small">
          پرداخت با کلیه کارت های اعتباری شرکت
        </p>
        <Gateways
          class="mt-4"
          @gatewaySelected="onGatewayClicked"
          :selected-gateway="selectedGateway"
        />
      </section>
      <section class="col-md-6 col-12 px-sm-3 px-0">
        <div
          class="p-3 order-type bg-color-fa fontsize-medium mb-3 mt-md-0 mt-3 text-justify"
        >
          <span
            >نوع سفارش:
            <span
              @click="(e) => selectReserved(e, false)"
              class="cursor-pointer ml-3 mr-3"
            >
              <div class="align-self-center ml-2 d-inline-block">
                <label class="address-checked-container">
                  <input
                    type="radio"
                    class="ml-1"
                    name="reserved"
                    :checked="!reserved"
                  />
                  <span class="address-checkmark"></span>
                </label>
              </div>
              <span class="mr-3"> عادی </span>
            </span>
            <span
              class="cursor-pointer"
              @click="(e) => selectReserved(e, true)"
            >
              <div class="align-self-center ml-2 d-inline-block">
                <label class="address-checked-container">
                  <input
                    type="radio"
                    name="reserved"
                    class="ml-1"
                    :checked="reserved"
                  />
                  <span class="address-checkmark"></span>
                </label>
              </div>
              <span class="mr-3" v-if="canBeMergedByReservations">
                اضافه کردن به
                <a
                  target="_blank"
                  :href="'/order/' + canBeMergedByReservations.id"
                  >رزور قبلی</a
                >
              </span>
              <span class="mr-3" v-else> رزرو </span>
            </span>
          </span>
        </div>
        <div class="p-3 bg-color-fa mb-3 mt-md-0 mt-3 text-justify">
          <span class="text-color-666 fontsize-small">این سفارش به اسم </span>
          <span class="weight-bold fontsize13 text-color-444">
            {{ selectedAddress.first_name + " " + selectedAddress.last_name }}
          </span>
          <span class="text-color-666 fontsize-small">به آدرس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.address }}
          </span>
          <span class="text-color-666 fontsize-small">به شماره تماس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.mobile }}
          </span>
          <span class="text-color-666 fontsize-small"
            >ارسال و تحویل می گردد
          </span>
        </div>
        <div class="p-3 bg-color-fa border">
          <div class="border-bottom">
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"
                >مجموع قیمت های کالا:
              </span>
              <span>
                <span class="fontsize-medium ml-1">{{
                  (totalPrice + totalSaving) | price
                }}</span>
                <span class="fontsize-small text-color-999">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> تخفیف: </span>
              <span>
                <span class="ml-4" v-if="totalSaving === 0">―</span>
                <template v-else>
                  <span class="fontsize-medium ml-1 weight-bold">{{
                    totalSaving | price
                  }}</span>
                  <span class="fontsize-small">تومان</span>
                </template>
              </span>
            </div>
            <!-- saeed -->
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold">هزینه بسته بندی</span>
              <span>
                <!--   -->
                <!--  -->
                <span class="fontsize-medium mr-1" v-if="groupingPrice === 0">
                  رایگان</span
                >
                <template v-else>
                  {{ groupingPrice | price }}
                  <span class="fontsize-medium ml-1"></span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
              </span>
            </div>
            <!-- saeed -->
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> هزینه ارسال: </span>
              <span>
                <span class="fontsize-medium mr-1" v-if="shippingPrice === 0"
                  >رایگان</span
                >
                <template v-else>
                  <span class="fontsize-medium ml-1">{{
                    shippingPrice | price
                  }}</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
              </span>
            </div>
            <div
              v-if="discount && discount.discount > 0"
              class="text-color-444 d-flex justify-content-between mb-2"
            >
              <span class="fontsize-medium weight-bold text-color-discount">
                تخفیف از کوپن:
              </span>
              <span class="text-color-discount">
                <span class="fontsize-medium ml-1 weight-bold">{{
                  discount.discount | price
                }}</span>
                <span class="fontsize-small">تومان</span>
              </span>
            </div>
          </div>
          <div class="text-color-444 d-flex justify-content-between mt-2">
            <span class="fontsize-medium weight-bold"> پرداخت نهایی: </span>
            <span class="text-color-theme">
              <span class="weight-bold fontsize-large ml-1">{{
                finalTotal | price
              }}</span>
              <span class="fontsize-medium">تومان</span>
            </span>
          </div>
          <template v-if="isPayingHalfHalf">
            <div class="text-color-444 d-flex justify-content-between mt-2">
              <span class="fontsize-medium weight-bold">
                پرداخت از کیف پول:
              </span>
              <span class="text-color-theme">
                <span class="weight-bold fontsize-large ml-1">{{
                  user.wallet.balance | price
                }}</span>
                <span class="fontsize-medium">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mt-2">
              <span class="fontsize-medium weight-bold">
                پرداخت از درگاه:
              </span>
              <span class="text-color-theme">
                <span class="weight-bold fontsize-large ml-1 text-danger">{{
                  (finalTotal - user.wallet.balance) | price
                }}</span>
                <span class="fontsize-medium">تومان</span>
              </span>
            </div>
          </template>
        </div>
      </section>
    </div>
    <div
      class="d-flex justify-content-between align-items-baseline py-3 px-sm-3 px-0"
    >
      <!-- <p class="fontsize13 text-color-666 d-md-block d-none">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
      </p> -->
      <button
        id="prev-btn-step-3"
        class="continue-buy-process py-2 w-25 ml-2 fontsize-medium text-center"
        @click="prevPage"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="10"
          height="10"
          viewBox="0 0 172 172"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path d="M0,172v-172h172v172z" fill="none"></path>
            <g fill="#ffffff">
              <path
                d="M62.39031,10.32c-0.90031,0.01344 -1.74688,0.36281 -2.39188,0.99438l-20.72062,20.55937c-1.34375,1.34375 -1.35719,3.52063 -0.02688,4.86438l48.96625,49.36937l-49.35594,48.96625c-1.35719,1.34375 -1.37062,3.52063 -0.02687,4.86438l20.55937,20.72062c1.34375,1.35719 3.52063,1.37063 4.86438,0.02688l72.53562,-71.94438c1.34375,-1.34375 1.35719,-3.52062 0.01344,-4.87781l-71.93094,-72.52219c-0.65844,-0.67187 -1.55875,-1.03469 -2.48594,-1.02125z"
              ></path>
            </g>
          </g>
        </svg>
        مرحله قبل
      </button>
      <button
        class="continue-buy-process py-2 w-25 text-center fontsize-medium"
        :disabled="
          waitingForPayStatus != 'init' && waitingForPayStatus != 'error'
        "
        @click="pay"
      >
        <template v-if="waitingForPayStatus == 'init'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'loading'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'redirecting'"
          >درحال انتقال به درگاه</template
        >
        <template v-else-if="waitingForPayStatus == 'error'"
          >تلاش مجدد</template
        >
      </button>
    </div>
    <div v-if="showTheremsModal" id="theremsModal">
      <div id="content">
        <p>
          سلام به دوستداران همیشگی فروشگاه نی نی پلاس
          <br />
          در نظر داشته باشید که محصولات شامل تخفیف در زمره محصولات فروش قطعی
          قرار دارند و غیر قابل تعویض و مرجوع خواهند بود
          <br />
        </p>
        <div id="checkedAccept">
          <input type="checkbox" v-model="accept" />
          <label for="">موارد فوق مورد تایید است</label>
        </div>

        <div id="buttons">
          <button @click="showTheremsModal = false" id="cnclBtn">لغو</button>
          <button
            :class="{ disable: accept != true }"
            id="accBtn"
            @click="acceptAndTherme()"
          >
            تایید
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal fade mobile-friendly"
      id="PackingPopup"
      tabindex="-1"
      aria-labelledby="AddAddressLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header text-right">
            <button
              type="button"
              class="close ml-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h6
              class="modal-title weight-bold text-color-444 mx-auto"
              id="AddAddressLAbel"
            >
              خریدار عزیز
            </h6>
          </div>
          <div class="modal-body add-package-type text-center px-4">
            <h4>
              با احترام لطفا بعد از پرداخت وجه , حتما دکمه تکمیل فرآیند خرید و
              بازگشت را فشار دهید
            </h4>
            <div id="userAccept">
              <label @click="userAccept = !userAccept" for="userAccept"
                >متوجه شدم</label
              >
              <input
                type="checkbox"
                v-model="userAccept"
                name=""
                id="userAccept"
              />
            </div>
          </div>
          <div class="packing-confirm-button-container">
            <button
              class="packing-confirm-button"
              :disabled="!userAccept"
              @click="acceptTherems()"
            >
              تایید
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="cartNotificationsPopup"
      tabindex="-1"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header align-items-center">
            <h6 class="modal-title fontsize18 m-0">خریدار عزیز</h6>
            <button
              type="button"
              class="bg-none fontsize20 px-2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <p class="fontsize18 m-0 pt-3">
              لطفا قبل از ورود به درگاه فیلترشکن خود را خاموش کنید.
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-center py-4">
            <button
              class="continue-buy-process py-2 px-3 text-center fontsize-medium"
              @click="acceptNotification"
            >
              متوجه شدم
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Gateways from "../shared/Gateways";
export default {
  name: "CartStep3",
  components: { Gateways },
  data() {
    return {
      requestingWallet: false,
      showTheremsModal: false,
      accept: false,
      userAccept: false,
      isNotificationShow: false,
    };
  },
  props: {
    gateways: Array,
    selectedGateway: Object,
    selectedAddress: Object,
    discount: Number,
    shippingPrice: Number,
    total: Number,
    totalSaving: Number,
    totalPrice: Number,
    payWallet: Boolean,
    reserved: Boolean,
    waitingForPayStatus: String,
    finalTotal: Number,
    reservations: Array,
    selectedShipping: Object,
    canBeMergedByReservations: [Object, Boolean],
  },
  mounted() {
    // Select default
    if (this.gateways && this.gateways.length && !this.selectedGateway) {
      this.$emit("gatewaySelected", this.gateways[0]);
    }
  },
  computed: {
    groupingPrice() {
      // return this.homeData
      return this.$store.getters["front/getPackagePrice"];
    },
    user() {
      return this.homeData.user.user;
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    isPayingHalfHalf() {
      return this.payWallet && this.finalTotal > this.user.wallet.balance;
    },
  },
  methods: {
    showPopupPlz() {
      window.$("#PackingPopUp").modal("show");
    },
    checkDiscountPermission() {
      if (this.totalSaving != 0 || this.discount != null) {
        return true;
      } else {
        return false;
      }
    },
    acceptAndTherme() {
      this.showTheremsModal = false;
      this.pay();
    },
    acceptTherems() {
      if (this.checkDiscountPermission()) {
        this.showTheremsModal = true;
      } else {
        this.acceptAndTherme();
      }
    },
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    onGatewayClicked(gateway) {
      this.$emit("gatewaySelected", gateway);
    },
    pay() {
      if (!this.isNotificationShow) {
        this.showCartNotificationsPopup();
      } else {
        this.$emit("pay");
      }
    },
    async selectPayMethod(e, wallet) {
      if (this.requestingWallet) {
        return;
      }
      this.$nextTick(async () => {
        if (wallet && !this.payWallet) {
          if (this.user.wallet.balance < this.finalTotal) {
            this.requestingWallet = true;
            try {
              const response = await this.$axios.get("customer/get-balance");
              this.user.wallet.balance = response.data;
            } catch (e) {
              this.$root.notify(e);
            } finally {
              this.requestingWallet = false;
            }
          }
          if (this.user.wallet.balance > 0) {
            this.$emit("payMethodSelected", true);
            this.$refs.radioWallet.click();
          } else {
            e.preventDefault();
            this.$refs.radioGateway.click();
            this.$root.notify("موجودی کیف پول شما کافی نیست", "warning");
          }
        } else if (!wallet && this.payWallet) {
          this.$refs.radioGateway.click();
          this.$emit("payMethodSelected", false);
        }
      });
    },
    selectReserved(e, reserved) {
      this.$emit("reserveSelected", reserved);
    },
    showCartNotificationsPopup() {
      window.$("#cartNotificationsPopup").modal("show");
    },
    acceptNotification() {
      window.$("#cartNotificationsPopup").modal("hide");
      this.isNotificationShow = true;
    },
  },
};
</script>
<style scoped>
div#userAccept label {
  margin: 0;
  font-size: 18px;
}
div#userAccept input {
  width: 30px;
  height: 30px;
  accent-color: var(--color-theme);
}
div#userAccept {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.wallet-img {
  width: 27px;
  height: 24px;
}
.order-type .address-checkmark,
.order-type .address-checkmark::after {
  border-radius: 25px;
}

#theremsModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000ba;
  display: flex;
}
div#content {
  width: 40%;
  background: white;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
}
div#content p {
  font-size: 20px;
  line-height: 40px;
}
div#checkedAccept {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
div#checkedAccept input {
  width: 30px;
  height: 30px;
}
div#checkedAccept label {
  margin: 0;
}
div#buttons button {
  padding: 10px 35px;
  border-radius: 5px;
  color: white;
}

div#buttons {
  display: flex;
  gap: 20px;
  width: 80%;
  margin: auto;
  justify-content: center;
}
div#buttons #accBtn {
  background: blue;
}
div#buttons #cnclBtn {
  background: red;
}

div#buttons .disable {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 768px) {
  div#content {
    width: 90%;
  }
  div#content p {
    font-size: 18px;
    text-align: justify;
  }
}
</style>
