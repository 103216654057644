<template>
  <section class=" weblog-comments-section  bg-white box-shaddow20 p-3 mt-4">
    <div class="w-100">
      <div class="d-flex justify-content-start w-100" style="gap: 11px" v-if="blogStatus">
        <b-skeleton type="button" />
        <b-skeleton type="button" />
      </div>
      <div v-else>
        <h6 class="text-color-444 fontsize-medium">دیدگاهی بگذارید</h6>
      </div>
      <br v-if="blogStatus"/>
      <p v-else class="d-inline-block text-color-444 fontsize-mini">
        نشانی ایمیل شما منتشر نخواهد شد
      </p>
      <CommentForm @commentAdded="onCommentAdded" v-if="blog" :blog-id="blog.post.id" />
    </div>
    <div class="weblog-comments" id="legal-questions">
      <b-skeleton v-for="i in 4" v-if="blogStatus"></b-skeleton>
      <!-- کامنت  -->
      <template v-if="blog && !blogStatus">
        <div v-for="(comment, index) in blog.post.comments" :key="'comment' + index" class="pb-md-0">
          <div class="d-flex pt-3 pb-1 pb-sm-1 px-sm-3 pl-4 pr-1 user-question">
            <div class="question-tab-content-img ml-3">
              <svg fill="none" height="17" viewBox="0 0 24 24" width="17" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z" fill="#fff" fill-rule="evenodd"/>
              </svg>
            </div>
            <Comment @commentAdded="onCommentAdded" @answer="answer" :blog-id="blog.post.id" :answering-comment="answeringComment" :answerable="true" :comment="comment" />
          </div>
          <template v-if="comment.children.length != 0">
            <!-- پاسخ کامنت -->
            <div v-for="(childComment, index) in comment.children"
                 :key="'answer' + index" class="d-flex py-3 px-sm-5 pl-4 pr-1 admin-answer">
              <div class="answer-tab-content-img ml-3">
                <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z" fill="#fff"/>
                </svg>
              </div>
              <Comment :answerable="false" :comment="childComment" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import CommentForm from "@/parts/Front/components/weblog/CommentForm";
import Comment from "@/parts/Front/components/weblog/Comment";
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "CommentSection",
  components: {Comment, CommentForm, BSkeleton},
  data() {
    return {
      answeringComment: null
    }
  },
  methods: {
    answer(commentId) {
      if (this.answeringComment === commentId) {
        this.answeringComment = null
      } else {
        this.answeringComment = commentId
        this.$nextTick(() => {
          document.querySelector('#form-' + commentId + ' input').focus();
        })
      }
    },
    onCommentAdded(comment) {
      if (comment) {
        this.blog.post.comments.unshift(comment)
      }
      this.answeringComment = null;
    }
  },
  computed: {
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
    blog() {
      return this.$store.getters["front/getBlogDetail"];
    },
  },
}
</script>
