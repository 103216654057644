<template>
    <div class="container pt-2 mt-3">
      <b-skeleton-img v-if="status" />
      <div v-else v-html="content.text"></div>
    </div>
</template>
<script>
import {BSkeletonImg} from 'bootstrap-vue'
export default {
  name: 'pages',
  components: {
    BSkeletonImg
  },
    // ارسال درخواست برای دریافت محتویات صفحه قبل از ساخت کامپوننت
    beforeCreate(){
        this.$store.dispatch("front/getPageFromServer",this.$route.params.page)
    },
    computed:{
        content(){
            return this.$store.getters["front/getPageContent"];
        },
        status(){
            return this.$store.getters["front/getPageContentStatus"];
        }
    },
    metaInfo() {
      return {
        meta: [
          {
            name: 'title',
            content: this.content ? this.content.title : "صفحات"
          },
          {property: 'og:title', content: this.content? this.content.title : false },
          {name: 'robots', content: 'index,follow'}
        ]
      }
    }
}
</script>
