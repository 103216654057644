<template>
  <div v-if="tab === 1">
    <section
      v-if="tab === 1 && showEditForm == false"
      class="user-information p-3 bg-white   box-shaddow20 position-relative overflow-auto"
    >
      <h4 class="text-center ">اطلاعات کاربری</h4>
      <LineGrow open-width="90%" />
      <div class="user-information-content row">
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">نام و نام خانوادگی:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6 v-if="user.full_name != null" class="fontsize15 text-color-444">
              {{ user.full_name }}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              مشخصاتی وارد نشده است
            </h6>
          </template>
        </div>
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">ایمیل:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6 v-if="user.email != null" class="fontsize15 text-color-444">
              {{ user.email }}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              ایمیلی وارد نشده است
            </h6>
          </template>
        </div>
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">شماره کارت:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6
              v-if="user.card_number != null"
              class="fontsize15 text-color-444"
            >
              {{ user.card_number.slice(0, 4) }}-{{user.card_number.slice(4, 8)}}-{{user.card_number.slice(8, 12)}}-{{user.card_number.slice(12, 16)}}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              شماره کارتی وارد نشده است
            </h6>
          </template>
        </div>
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">شماره تماس ضروری:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6 v-if="user.mobile" class="fontsize15 text-color-444">
              {{ user.mobile }}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              شماره موبایلی وارد نشده است
            </h6>
          </template>
        </div>
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">تاریخ تولد:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6 v-if="user.birth_date" class="fontsize15 text-color-444">
              <!-- {{ new Date(user.birth_date).toLocaleDateString("fa-IR") }} -->
              {{ user.birth_date }}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              تاریخ تولدی وارد نشده است
            </h6>
          </template>
        </div>
        <div class="col-lg-4 col-12 py-3">
          <h6 class="fontsize15 text-color-999">کدملی:</h6>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <template v-else>
            <h6 v-if="user.national_code" class="fontsize15 text-color-444">
              {{ user.national_code }}
            </h6>
            <h6 v-else class="fontsize15 text-color-444">
              کد ملی را وارد نکرده اید
            </h6>
          </template>
        </div>
      </div>
      <button
          @click="showEditForm = true"
          class="edit-profile-btn bg-color-theme px-3 py-2 border-radius7">
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
              fill="#fff"
          />
        </svg>
        <span class="text-white fontsize14 mr-1">ویرایش</span>
      </button>
    </section>
    <section
      v-if="tab === 1 && showEditForm"
      class="p-3 bg-white   box-shaddow20"
    >
      <form class="">
        <div class="user-info-edit">
          <div class="fontsize15 weight-bold text-color-444 border-bottom p-3">
            <h4>اطلاعات حساب شخصی </h4>
          </div>

          <div id="updateForm">
            <div class="mb-lg-0 mb-4">
              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">نام:</label>
                <input
                  class="text-color-444   bg-color-eai p-md-3 p-2"
                  type="text"
                  id="firstName"
                  :class="{ inputError: firstNameError }"
                  v-model="first_name"
                />
              </div>
              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">نام خانوادگی:</label>
                <input
                  class="text-color-444   bg-color-eai p-md-3 p-2"
                  type="text"
                  id="lastName"
                  :class="{ inputError: lastNameError }"
                  v-model="last_name"
                />
              </div>
              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">ایمیل:</label>
                <input
                  class="text-color-444   bg-color-eai p-md-3 p-2"
                  type="email"
                  id="email"
                  :class="{ inputError: emailError }"
                  v-model="email"
                />
              </div>

              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">رمز عبور:</label>
                <input
                  class="text-color-444   bg-color-eai p-md-3 p-2"
                  type="password"
                  :class="{ inputError: newPasswordError }"
                  id="newPassword"
                  v-model="newPassword"
                />
              </div>
            </div>
            <div class="mb-lg-0 mb-4 q2">
              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">تاریخ تولد:</label>
                <date-picker
                  id="brithDay"
                  v-model="brithDay"
                  format="jYYYY-jMM-jDD"
                  color="#121314"
                />
                <!-- display-format="jYYYY/jMM/jDD" -->
              </div>
              <div
                v-if="!foreignNational"
                class="p-3 d-flex fontsize14 flex-column weight-bold"
              >
                <label class="text-color-999" for="">کدملی:</label>
                <input
                  class="text-color-444   bg-color-eai p-md-3 p-2"
                  type="text"
                  id="nationalCode"
                  :class="{ inputError: nationalCodeError }"
                  v-model="national_code"
                />
              </div>
              <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                <label class="text-color-999" for="">شماره کارت:</label>
                <div
                  id="cartInputs"
                  :class="{ inputError: cardNumberError }"
                  class="d-flex bg-color-eai p-3  "
                >
                  <input
                    class="bg-none w-25 text-center"
                    :type="mediaQueries.mobileSize ? 'tel' : 'text'"
                    v-number
                    maxlength="4"
                    placeholder="- - - -"
                    @click="selectInput(1)"
                    @input="changeCart"
                    @keyup="clearCart"
                    ref="cartNumber1"
                    data-num="1"
                  />
                  <input
                    class="bg-none w-25 text-center"
                    :type="mediaQueries.mobileSize ? 'tel' : 'text'"
                    v-number
                    maxlength="4"
                    @input="changeCart"
                    @click="selectInput(2)"
                    @keyup="clearCart"
                    ref="cartNumber2"
                    placeholder="- - - -"
                    data-num="2"
                  />
                  <input
                    class="bg-none w-25 text-center"
                    :type="mediaQueries.mobileSize ? 'tel' : 'text'"
                    v-number
                    @input="changeCart"
                    @keyup="clearCart"
                    @click="selectInput(3)"
                    ref="cartNumber3"
                    maxlength="4"
                    placeholder="- - - -"
                    data-num="3"
                  />
                  <input
                    class="bg-none w-25 text-center"
                    @input="changeCart"
                    @keyup="clearCart"
                    @click="selectInput(4)"
                    ref="cartNumber4"
                    :type="mediaQueries.mobileSize ? 'tel' : 'text'"
                    v-number
                    maxlength="4"
                    placeholder="- - - -"
                    data-num="4"
                  />
                </div>
              </div>
              <div
                id="chooseGenderAndImageBox"
                class=" m-3 pb-3 d-flex fontsize14 flex-row weight-bold border-bottom">
                <div class="d-flex">
                  <span class="text-color-999 ml-4 mt-1">جنسیت:</span>
                  <div >
                    <treeselect
                        placeholder="جنسیت"
                        id="selectGender"
                        v-if="user"
                        :clearable="false"
                        :options="genderOption"
                        v-model="gender"
                    />
                  </div>
                </div>

                <button id="selectProfileImage" @click.prevent="selectFile()">
                  تصویر پروفایل
                </button>
                <input
                  id="chooseImage"
                  type="file"
                  accept="image/*"
                  @change="changeProfileImage"
                />
              </div>

              <div class="fontsize14 text-color-444 pr-5">
                <div
                  @click="newsletter = !newsletter"
                  class="custom-control custom-switch"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="newslettrer"
                    v-model="newsletter"
                  />
                  <label
                    class="custom-control-label text-color-666"
                    for="customSwitch1"
                    >عضویت در خبرنامه</label
                  >
                </div>
                <div
                  @click="foreignNational = !foreignNational"
                  class="custom-control custom-switch"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="foreignNational"
                    v-model="foreignNational"
                  />
                  <label
                    class="custom-control-label text-color-666"
                    for="customSwitch2"
                    >تابعیت خارجی</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-3 pt-3 border-top">
          <input
            @click.prevent="updateUser()"
            :class="{ disabled: disabled }"
            type="submit"
            class="
              text-white
              py-2
              px-4
              bg-color-theme

              fontsize14
            "
            value="ثبت اطلاعات کاربری"
          />
          <button
            @click.prevent="closeForm()"
            class="
              py-2
              text-color-666
              px-4
              mr-3
              border
              bg-none

              fontsize14
            "
          >
            انصراف
          </button>
        </div>
      </form>
    </section>
    <CropImage v-model="showBox" :image="img" />
  </div>
</template>
<script>
import CropImage from "@/parts/Front/components/CropImage";
import {BSkeleton} from 'bootstrap-vue'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {Treeselect} from "@riophae/vue-treeselect";
import LineGrow from "@/parts/Front/components/shared/LineGrow";

export default {
  watch: {
    user: {
      handler: function () {
        if (this.user) {
          this.gender = this.user.gender;
          this.first_name = this.user.first_name;
          this.last_name = this.user.last_name;
          this.email = this.user.email;
          this.national_code = this.user.national_code;
        }
      },
      immediate:true
    },
    updateError(newVal) {
      if (newVal != '') {
        this.$root.notify(newVal, 'warning')
      }
    },
    showEditForm(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.foreignNational = this.user.foreign_national == 1;
          this.newsletter = this.user.newsletter == 1;
          // تنظیم کردن زمان به فرمت اولیه
          this.brithDay = this.user.birth_date;
          if (this.user.card_number != null) {
            this.$refs.cartNumber1.value = this.user.card_number.slice(0, 4);
            this.$refs.cartNumber2.value = this.user.card_number.slice(4, 8);
            this.$refs.cartNumber3.value = this.user.card_number.slice(8, 12);
            this.$refs.cartNumber4.value = this.user.card_number.slice(12, 16);
          }
        });
      }
    },
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      national_code: null,
      img: null,
      disabled: false,
      showBox: false,
      showEditForm: false,
      brithDay: 62513822481,
      changePass: false,
      newPassword: null,
      gender: "female",
      genderOption: [
        {
          id: "male",
          label: "مرد",
        },
        {
          id: "female",
          label: "زن",
        },
      ],
      updateError: "",
      firstNameError: false,
      lastNameError: false,
      nationalCodeError: false,
      emailError: false,
      newPasswordError: false,
      cardNumberError: false,
      genderError: false,
      newsletter: true,
      foreignNational: true,
    };
  },
  props: { tab: { Number } },
  computed: {
    user() {
      return this.$store.getters["front/getUserDetail"];
    },
    status() {
      return this.$store.getters["front/getUserDetailStatus"];
    },
  },
  methods: {
    selectFile() {
      document.getElementById("chooseImage").click();
    },
    changeProfileImage(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      else {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.showBox = true;
          this.img = e.target.result;
        };

        reader.readAsDataURL(files[0]);
      }
    },
    // زمانی که روی ورودی های کارت کلیک میشه اونی که خالیه انتخاب میشه
    selectInput(id) {
      let input1 = this.$refs.cartNumber1.value;
      let input2 = this.$refs.cartNumber2.value;
      let input3 = this.$refs.cartNumber3.value;
      let input4 = this.$refs.cartNumber4.value;
      if (this.$refs["cartNumber" + id].value) {
        return this.$refs["cartNumber" + id].select();
      }
      // درصورت خالی بودن ورودی ها
      if (input1 == "" || input1.length != 4) {
        return this.$refs.cartNumber1.focus();
      } else if (input2 == "" || input2.length != 4) {
        return this.$refs.cartNumber2.focus();
      } else if (input3 == "" || input3.length != 4) {
        return this.$refs.cartNumber3.focus();
      } else if (input4 == "" || input4.length != 4) {
        return this.$refs.cartNumber4.focus();
      } else {
        this.$refs["cartNumber" + id].select();
      }
    },
    // بستن فرم و رفتن به صفحه قبل اطلاعات کاربری
    closeForm() {
      this.showEditForm = false;
      document.getElementById("pagePath").scrollIntoView();
    },
    // بروز رسانی اطلاعات کاربر
    updateUser() {
      // احراز هویت فرم
      if (this.first_name == null || this.first_name.length < 2) {
        this.firstNameError = true;
        document.getElementById("firstName").focus();
        this.updateError = "لطفا نام خود را به صورت کامل وارد کنید";
        return document.getElementById("firstName").scrollIntoView();
      } else {
        this.firstNameError = false;
        this.updateError = "";
      }
      if (this.last_name == null || this.last_name.length < 2) {
        this.lastNameError = true;
        document.getElementById("lastName").focus();
        this.updateError = "لطفا نام خانوادگی خود را به صورت کامل وارد کنید";
        return document.getElementById("lastName").scrollIntoView();
      } else {
        this.lastNameError = false;
        this.updateError = "";
      }
      if (!this.foreignNational) {
        if (this.national_code && this.national_code.length != 10) {
          this.nationalCodeError = true;
          this.updateError = "شناسه کدملی ده رقمی است ";
          document.getElementById("nationalCode").focus();
          return document.getElementById("nationalCode").scrollIntoView();
        } else {
          this.nationalCodeError = false;
          this.updateError = "";
        }
      }
      if (this.email != null && this.email.length > 5) {
        if (!this.validEmail(this.email)) {
          this.emailError = true;
          document.getElementById("email").focus();
          this.updateError = "فرمت ایمیل به درستی وارد نشده است";
          return document.getElementById("email").scrollIntoView();
        } else {
          this.emailError = false;
          this.updateError = "";
        }
      }
      if (this.newPassword != null && this.newPassword.length < 6) {
        this.newPasswordError = true;
        this.updateError = "رمز عبور باید حداقل 6 کارکتر داشته باشد";
        document.getElementById("newPassword").focus();
        return document.getElementById("newPassword").scrollIntoView();
      } else {
        this.newPasswordError = false;
        this.updateError = "";
      }
      if (false && this.gender == null) {
        this.genderError = true;
        this.updateError = "لطفا جنسیت خود را انتخاب کنید";
        return document.getElementById("selectGender").scrollIntoView();
      } else {
        this.genderError = false;
        this.updateError = "";
      }
      let card_number = '';
      card_number += this.$refs.cartNumber1.value
      card_number += this.$refs.cartNumber2.value
      card_number += this.$refs.cartNumber3.value
      card_number += this.$refs.cartNumber4.value
      card_number = (card_number.length === 16 || card_number.length === 0) ? card_number : null;
      // بعد از اعتبار سنجی ارسال ورودی ها
      const formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append("first_name", this.first_name);
      (card_number !== null) && formData.append("card_number", card_number);
      formData.append("last_name", this.last_name);
      if (this.foreignNational) {
        formData.append("foreign_national", 1);
      } else if (this.national_code) {
        formData.append("foreign_national", 0);
        formData.append("national_code", this.national_code);
      }
      formData.append("newsletter", this.newsletter ? 1 : 0);
      if (this.email != null) {
        formData.append("email", this.email);
      }
      this.gender !== null && formData.append("gender", this.gender);

      if (this.newPassword != null) {
        formData.append("password", this.newPassword);
      }

      if (this.brithDay != null) {
        if (this.brithDay != this.user.birth_date) {
          formData.append("birth_date", this.brithDay);
        } else {
          formData.append("birth_date", this.brithDay);
        }
      }
      this.disabled = true;
      this.$axios
        .post("/customer/profile", formData)
        .then((res) => {
          this.$root.notify(res.data.message, 'success')
          this.$store.commit("front/setUserDetail", res.data.data.customer);
          this.disabled = false;
          this.showEditForm = false
          this.newPassword = null
        })
        .catch((error) => {

          this.$root.notify(error);
        }).finally(() => this.disabled = false);
    },
    // اعتبار سنجی ایمیل
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // با تغییر کارت حرکت به سمت اینپوت بعدی
    changeCart(event) {
      let number = Number.parseInt(event.target.dataset.num);
      if (event.srcElement.value.length == event.target.maxLength) {
        if (number == 4) {
          // اگر اخرین اینپوت بود
          return;
        } else {
          this.$refs["cartNumber" + (number + 1)].select()
        }
      }
    },
    // پاک کردن ورودی های کارت
    clearCart(event) {
      // در صورت پاک کرن بین اینپوت ها حرکت کند
      if (event.key == "Backspace") {
        if (event.target.value.length == 0) {
          let number = Number.parseInt(event.target.dataset.num);
          this.$refs["cartNumber" + (number - 1)].select()
        }
      }
    },
  },
  mounted() {
    this.$store.commit('front/addHomeRetrievedEvent', () => {
      if (this.$route.query.edit) {
        this.showEditForm = true
      }
    })
  },
  inject: ["mediaQueries"],
  components: {
    LineGrow,
    CropImage,
    Treeselect,
    BSkeleton
  },
};
</script>
<style scoped>
/* تغییر استاییل و افزودن استایل به کدهای اضاف شده */
#updateForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 600px){
  #updateForm {
    grid-template-columns: 1fr;
  }
}
.q2{
margin-top:15px;
}
.user-info-edit {
  display: grid;
  grid-template-columns: 1fr;
}


.user-info-edit .custom-switch .custom-control-input:checked~.custom-control-label::after {
  /*background-color: #fff;*/
  transform: translateX(-.75rem) !important;
}

.user-info-edit .custom-switch .custom-control-label::after {
  /*top: calc(.25rem + 2px);*/
  right: calc(-2.25rem + 2px) !important;
}

.user-info-edit .custom-switch .custom-control-label::before {
  right: -2.25rem !important;
}

.user-info-edit .custom-control-input {
  /*position: absolute;*/
  right: 0 !important;
  left: unset !important;
}


/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#cartInputs {
  direction: ltr;
}
#selectGender {
  width: 105px;
}
#changePassBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.inputError {
  background-color: #f5eae7;
  border: 1px solid pink;
}

#chooseImage {
  opacity: 0;
  position: fixed;
  z-index: 0;
  left: -200px;
  top: -200px;
}
#selectProfileImage {
  padding: 5px;
  background: #121314;
  color: white;
  border-radius: 5px;
}
#chooseGenderAndImageBox {
  display: flex;
  justify-content: space-around;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.user-information-content>>>div:not(:nth-child(3n)) {
  border-left: 1px solid var(--color-a5);
}
</style>
